<template>
  <div ref="wrapper">
    <div v-if="field.label" class="row align-items-center">
      <div class="col">
        <label>
          {{ field.label }}
          <span v-if="field.required" class="red--text">*</span>
          <i
            v-if="field.helpSnippet"
            v-b-popover.hover.top="$t(field.helpSnippet)"
            class="fal fa-question-circle ml-1"
          />
        </label>
      </div>
    </div>
    <div class="d-flex flex-nowrap">
      <div style="flex-grow: 1; max-width: 100%">
        <div class="text-dark">{{ field.value }}</div>
        <!-- notification-text -->
        <span v-if="field.hint" class="form-text text-muted">
          {{ field.hint }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object
    }
  }
};
</script>
